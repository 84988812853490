<template>
    <modal-page-template title="Définir un nouveau mot de passe">
        <b-col lg="8" offset-lg="2">
            <b-form @submit.prevent="submit">
                <b-form-group>
                    <label v-b-tooltip.hover="'Votre mot de passe doit faire au moins 8 caractères'" slot="label">
                        Mot de passe
                        <font-awesome-icon icon="question-circle"/>
                    </label>
                    <b-form-input v-model="form.password" type="password" required autocomplete="new-password"/>
                </b-form-group>

                <b-form-group label="Confirmation">
                    <b-form-input v-model="form.passwordConf" type="password" required autocomplete="new-password"/>
                </b-form-group>

                <b-btn type="submit" variant="secondary" class="m-3">
                    <font-awesome-icon icon="check"/>
                    Valider
                </b-btn>
            </b-form>
        </b-col>
    </modal-page-template>
</template>

<script>
    import {apiPath} from '@/util/http';

    const ModalPageTemplate = () => import('@/components/templates/ModalPageTemplate');

    export default {
        name: 'ResetPassword',
        components: {ModalPageTemplate},
        data: () => ({
            token: null,
            form: {
                password: null,
                passwordConf: null
            }
        }),
        methods: {
            submit() {
                this.axios.post(apiPath('reset_password'), {...this.form, token: this.token})
                    .then(() => {
                        this.$toaster.success('Mot de passe modifié avec succès');
                        this.$router.push({name: 'login'});
                    })
                    .catch(error => this.$toaster.error(error.response.data.error || 'Erreur inconnue'));
            }
        },
        mounted() {
            this.token = this.$route.query.token;

            if (!this.token) {
                this.$toaster.error('Le lien que vous avez suivi est incorrect');
            }
        }
    };
</script>
